import { message } from "../_mocks_/chantier";

const url =  process.env.REACT_APP_API_URL;

export const getMessagesChantier = async (idChantier) => {
    let messagesChantier = message.filter((message) => (message.chantierId === idChantier));

    return messagesChantier;
}

export const postMessage = async (idChantier, message) => {
    let newMessage = {
        chantierId: idChantier,
        message: message,
        date: new Date()
    }

    message.push(newMessage);
}

//LOGIN API CALL
export const getAuthenticated = async (password) => {
    const response = await fetch(`${url}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password })
    });
    const data = await response.json();

    return data;
}

//CHANTIER API CALL
export const getChantiers = async () => {
    performance.mark('start');
    const response = await fetch(`${url}/chantier`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
    const data = await response.json();
    // Après l'appel API
    performance.mark('end');
    performance.measure('temps de réponse', 'start', 'end');
    return data;
}

export const createChantier = async (file) => {
    const response = await fetch(`${url}/chantier`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'POST',
        body: file
    });
    const data = await response.json();

    return data;
}

export const modifyChantier = async (values , id) => {
    const response = await fetch(`${url}/chantier/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'PUT',
        body: JSON.stringify(values)
    });
    const data = await response.json();

    return data;
}            

export const getChantier = async (password) => {
    const response = await fetch(`${url}/loginChantier`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password })
    });
    const data = await response.json();
    
    return data;
}


// PERSONNEL API CALL
export const getPersonnels = async () => {
    const response = await fetch(`${url}/personnel`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    });
    const data = await response.json();

    return data;
}

export const createPersonnel = async (file) => {
    const response = await fetch(`${url}/personnel`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'POST',
        body: file
    });
    const data = await response.json();

    return data;
}

export const modifyPersonnel = async (file, id) => {
    const response = await fetch(`${url}/personnel/${id}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'PUT',
        body: file
    });
    const data = await response.json();

    return data;
}

export const deletePersonnels = async (id) => {
    const response = await fetch(`${url}/personnel/${id}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'DELETE',
    });
    const data = await response.json();

    return data;
}


// MATERIEL API CALL
export const addChantierFiles = async (file, id) => {
    const response = await fetch(`${url}/chantier/${id}/files`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'POST',
        body: file
    });
    const data = await response.json();

    console.log(data);

    return data;
}

export const createPlanningTask = async (values, id) => {
    const response = await fetch(`${url}/chantier/${id}/planning`, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'POST',
        body: JSON.stringify(values)
    });
    const data = await response.json();

    return data;
}

export const updatePlanningTask = async (values, id) => {
    const response = await fetch(`${url}/chantier/${id}/planning/${values.id}`, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'PUT',
        body: JSON.stringify(values)
    });
    const data = await response.json();

    return data;
}

export const deletePlanningTask = async (idTask, id) => {
    const response = await fetch(`${url}/chantier/${id}/planning/${idTask}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'DELETE',
    });
    const data = await response.json();

    return data;
}

export const createPointage = async (values, id) => {
    const response = await fetch(`${url}/chantier/${id}/pointage`, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'POST',
        body: JSON.stringify(values)
    });
    const data = await response.json();

    return data;
}

export const updatePointage = async (values, id) => {
    const response = await fetch(`${url}/chantier/${id}/pointage/${values.id}`, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'PUT',
        body: JSON.stringify(values)
    });
    const data = await response.json();

    return data;
}

export const updateListPersonnel = async (values, id) => {
    const response = await fetch(`${url}/chantier/${id}/personnel`, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'PUT',
        body: JSON.stringify(values)
    });
    const data = await response.json();

    return data;
}

export const addPersonnelDocument = async (file, id) => {
    const response = await fetch(`${url}/personnel/${id}/document`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'POST',
        body: file
    });
    const data = await response.json();

    return data;
}

export const deleteDocument = async (id, type) => {
    const response = await fetch(`${url}/document/${type}/${id}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        method: 'DELETE',
    });
    const data = await response.json();

    return data;
}
