import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Chantier from './pages/Chantier';
import Header from './components/Header';
import Admin from './pages/Admin';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stayConnected, getLoggedOut } from "./services/redux/adminSlice"
import AdminHeader from './components/AdminHeader';


function App() {
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const isLogged = useSelector(state => state.admin.connected);
    const url =  process.env.REACT_APP_API_URL;
    
    useEffect(() => {
        if (token) {
            (async () => {
                const response = await fetch(`${url}/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token
                    })
                });
                const data = await response.json();
                if (data.status === 200) {
                    dispatch(stayConnected());
                }
                else {
                    dispatch(getLoggedOut());
                    window.location = '/';
                }
            })();
        }
    }, [token, dispatch, url]);

    return (
        <div className="App">
            <BrowserRouter>
            {isLogged === true ? <AdminHeader /> : <Header />  }
                <Routes>
                    <Route path="/" element={isLogged === true ? <Admin /> : <Accueil />} />
                    <Route path="/Chantier" element={<Chantier />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
