import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { useSelector } from "react-redux";
import { deleteDocument } from "../services/apiCall";
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const Documents = ({ files }) => {
    const [numPages, setNumPages] = useState(null);
    const [file, setFile] = useState("");
    const isLogged = useSelector((state) => state.admin.connected);
    const personnel_document = useSelector(state => state.personnels.personnels.documents);
    const chantier_document = useSelector(state => state.chantier.documents);

    useEffect(() => {
        setFile(files);
    }, [files]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const closeModal = (e) => {
        const modal = document.querySelector(".modal-file.open");
        modal.classList.remove("open");
        document.body.classList.remove("modal-open")
    };

    const deleteDoc = async (file) => {
        let id = "";
        let type = "";
        if (personnel_document) {
            console.log(file);
            personnel_document.find((doc) => {
                if (doc.lien === file) {
                    id = doc.id;
                    type = "personnel";
                }
            })
        }
        if (chantier_document) {
            chantier_document.find((doc) => {
                if (doc.lien === file) {
                    console.log(doc);
                    id = doc.id;
                    type = "chantier";
                }
            })
        }
        let result = window.confirm("Voulez-vous vraiment supprimer ce document ?")
        if (result) {
            try {
                const res = await deleteDocument(id, type);
                console.log(res);
            } catch (error) {
                console.log(error);
            }
            closeModal();
        }
    };

    return (
        <div className='modal-file'>
            {
                !file.includes("proBTP") && isLogged ?
                    <button className="weeksBtn supp_doc" onClick={() => deleteDoc(file)}>
                        supprimer
                    </button>
                    : null
            }
            <div className="file-container">
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(e) => console.log(e)}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                </Document>
            </div>
            <button className="close" onClick={(e) => closeModal(e)}>
                <svg width="20" height="20" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4 13.7C13.8 13.3 13.8 12.7 13.4 12.3L8.1 7L13.4 1.7C13.8 1.3 13.8 0.7 13.4 0.3C13 -0.1 12.4 -0.1 12 0.3L6.85 5.45L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L6.85 8.55L12 13.7C12.2 13.9 12.4 14 12.7 14C13 14 13.2 13.9 13.4 13.7Z" />
                </svg>
            </button>
        </div>
    );
};

export default Documents;